<template>
    <ContentWrapper>
        <breadcrumb/>

        <div class="container">
            <div class="card card-default animated-fast fadeInLeft" :class="loading ? 'whirl' : ''">
                <div class="card-header text-bold">
                    <i class="fad fa-comment-dots"></i> {{$t('support.view.ticket.title')}}
                </div>
                <div class="card-body">

                    <b-row style="max-width: 700px">
                        <b-col sm="12">
                            <b>{{$t('support.view.ticket.subject')}}:</b> {{ ticket.title }}
                        </b-col>
                        <b-col sm="6">
                            <b>{{$t('support.view.ticket.created_at')}}:</b> {{ $moment.utc(ticket.created_at).local().format('LLL') }}
                        </b-col>
                        <b-col sm="6">
                            <b>{{$t('support.view.ticket.status')}}:</b> {{ ticket.status }}
                        </b-col>
                        <b-col sm="6">
                            <b>{{$t('support.view.ticket.id')}}:</b> #{{ ticket.id }}
                        </b-col>
                    </b-row>

                    <hr>

                    <b-btn class="mr-2" :to="{name: 'Support:Tickets'}">{{ $t('support.view.ticket.button_back') }}</b-btn>

                    <template v-if="ticket.status !== 'closed'">
                        <b-btn class="mr-2" variant="danger" @click="sendAnswer('Ticket closed by User', true)">{{ $t('support.view.ticket.button_close') }}</b-btn>

                        <p class="text-sm mt-2">
                            {{ $t('support.view.ticket.button_close_hint') }}
                        </p>

                        <hr>

                        <div>
                            {{ $t('support.view.ticket.field_answer') }}:
                            <b-textarea class="my-2" style="min-height: 150px" v-model="answer"></b-textarea>
                            <div class="text-right">
                                <b-btn class="ml-2" variant="primary" @click="sendAnswer(answer, false)">{{ $t('support.view.ticket.button_send') }}</b-btn>

                                <p class="text-sm mt-2 w-75 d-inline-block">
                                    <i class="fas fa-paperclip"></i> {{ $t('support.view.ticket.attachment_info') }}
                                </p>
                            </div>
                        </div>
                    </template>

                    <hr>

                    <div class="comment  mb-2" v-for="(comment, index) in ticket.comments">
                        <p :class="{'text-primary': index === 0}"><b>{{ comment.author.name }}</b> - {{ $moment.utc(comment.created_at).local().format('LLL') }}</p>
                        <pre class="border p-2" :class="{'border-primary': index === 0}">{{ comment.body }}</pre>
                    </div>

                </div>
            </div>
        </div>
    </ContentWrapper>
</template>
<style lang="scss" scoped>
    .comment {
    }
</style>
<script>


    export default {
        data() {
            return {
                ticket: [],
                answer: '',
                loading: true
            }
        },
        components: {},
        beforeMount() {
            this.loading = true;
            this.$api.get('support/tickets/' + this.$route.params.id).then(response => {
                this.ticket = response.data;
                this.loading = false;
            });
        },
        methods: {
            isMyComment(comment) {
                return comment.author.email === this.$store.getters.user('email')
            },
            sendAnswer(answer, solved = false) {
                this.loading = true;
                this.$api.post('support/tickets/' + this.ticket.id + '/comment', {body: answer, solved: solved}).then(response => {
                    this.ticket = response.data;
                    this.loading = false;
                })
            }
        }
    }
</script>